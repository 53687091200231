export const translateIngredientGroupToPageName = (name: string) => {
  return name
    .replace(/ /g, "-")
    .replace(/[\(\)]/g, "")
    .replace(/'/g, "")
    .replace(/"/g, "")
    .replace(/\./g, "")
    .replace(/--/g, "-")
    .toLowerCase();
};
