import { Router, useNavigate } from "@reach/router";

import React, { useEffect, useState } from "react";

import { translateIngredientGroupToPageName } from "../lib/format";
import Layout from "../components/common/Layout";
import SearchInput from "../components/common/SearchInput";
import HowItWorksCarousel from "../components/landing/HowItWorksCarousel";

import Medicine from "../img/icons/medicine.svg";
import GummyBear from "../img/icons/gummy-bear2.png";

function IndexPage() {
  const [url, setUrl] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (url) {
      navigate(url);
    }
  }, [url, navigate]);

  return (
    <Layout>
      <section className="hero is-medium is-surface">
        <div className="hero-body">
          <div className="container has-text-centered has-max-width-128">
            <p className="title is-1-adaptive mb-5">
              Find supplements that work for you.
            </p>
          </div>
          <form className="container has-max-width-256 mt-6">
            <div className="field has-addons">
              <div className="control is-expanded">
                <SearchInput
                  onSelected={(result) => {
                    if (result.brand) {
                      setUrl(
                        `/ingredient/${translateIngredientGroupToPageName(
                          result.ingredient
                        )}/?brands=${result.brand.toLowerCase()}`
                      );
                    } else {
                      setUrl(
                        `/ingredient/${translateIngredientGroupToPageName(
                          result.ingredient
                        )}/`
                      );
                    }
                  }}
                />
              </div>
              <p className="control">
                <button className="button is-primary is-large">
                  <span className="icon">
                    <i className="fas fa-search" />
                  </span>
                  <span>Find supplements</span>
                </button>
              </p>
            </div>
          </form>
          <div className="container has-max-width-256 mt-7">
            <h2 className="title is-4">Popular Searches</h2>
            <div className="buttons">
              {[
                { name: "melatonin", label: "Melatonin" },
                { name: "magnesium", label: "Magnesium" },
                { name: "calcium", label: "Calcium" },
                { name: "vitamin-d", label: "Vitamin D" },
                { name: "curcumin", label: "Curcumin" },
              ].map(({ name, label }, index) => (
                <a
                  href={`/ingredient/${name}`}
                  className="button is-surface-darker is-outlined is-outlined-darkened"
                  key={`button-${name}-${index}`}
                >
                  {label}
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/*<section className="section">
        <div className="container has-max-width-192">
          <h2 className="title is-3 ml-4">How Without Care Works</h2>
          <HowItWorksCarousel />
        </div>
        <div className="container has-max-width-192 mt-6">
          <h2 className="title is-3 ml-4">Choose what works for you</h2>
          <div className="content is-small has-text-centered">
            <div className="columns is-centered is-multiline">
              <div className="column is-one-third-desktop">
                <div className="box">
                  <figure className="image is-64x64 mx-auto">
                    <img src={Medicine} />
                  </figure>
                  <h3 className="title is-6 mb-2">Melatonin</h3>
                  <p>Find a remedy to sleep better.</p>
                  <a href="/ingredients/melatonin">Check it out</a>
                </div>
              </div>
              <div className="column is-one-third-desktop">
                <div className="box">
                  <figure className="image is-64x64 mx-auto">
                    <img src={GummyBear} />
                  </figure>
                  <h3 className="title is-6 mb-2">Dietary needs? We got you.</h3>
                  <p>Find drugs that fit your dietary and religious beliefs.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-eggshell has-text-centered">
        <div className="container has-max-width-192">
          <h2 className="title is-3 ml-4 mb-6">Did you know?</h2>
          <div className="columns is-vcentered">
            <div className="column is-one-third">
              <p className="is-size-1 has-text-weight-bold">75%</p>
              <p>Other text</p>
            </div>
            <div className="column is-narrow">
              <div className="has-background-white-ter is-hidden-mobile" style={{ height: 50, width: 1 }} />
            </div>
            <div className="column is-one-third">
              <p className="is-size-1 has-text-weight-bold">75%</p>
              <p>Other text</p>
            </div>
            <div className="column is-narrow">
              <div className="has-background-white-ter is-hidden-mobile" style={{ height: 50, width: 1 }} />
            </div>
            <div className="column is-one-third">
              <p className="is-size-1 has-text-weight-bold">75%</p>
              <p>Other text</p>
            </div>
          </div>
        </div>
      </section>*/}
    </Layout>
  );
}

export default () => (
  <Router>
    <IndexPage default />
  </Router>
);
