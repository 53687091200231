import { useDebounceCallback } from "@react-hook/debounce";

import React, { useCallback, useMemo, useState } from "react";
import Autosuggest, {
  GetSuggestionValue,
  InputProps,
  RenderInputComponent,
  RenderSuggestionsContainer,
} from "react-autosuggest";

interface SearchResult {
  kind: "product" | "ingredient_group";
  label: string;
  ingredient: string;
  brand?: string;
}

interface SingleProps {
  onSelected: (result: SearchResult) => void;
  isMulti?: false;
}

interface MultiProps {
  onSelected: (result: SearchResult) => void;
  onRemoveSelected: (result: SearchResult) => void;
  selected: SearchResult[];
  isMulti: true;
}

type Props = SingleProps | MultiProps;

const getSuggestionValue: GetSuggestionValue<SearchResult> = (suggestion) =>
  suggestion.label;

const renderSuggestion = (suggestion: SearchResult) => (
  <div>
    <strong>
      {suggestion.kind === "ingredient_group" ? "Ingredient" : "Product"}
    </strong>
    : <span>{suggestion.label.toLowerCase()}</span>
  </div>
);

const renderSuggestionsContainer: RenderSuggestionsContainer = ({
  containerProps,
  children,
}) => {
  return (
    <div
      {...containerProps}
      className={`${containerProps.className} has-width-full is-absolute is-z-top`}
    >
      {children}
    </div>
  );
};

const renderMultiInputComponent =
  (
    values: SearchResult[],
    onRemoveSelected: (r: SearchResult) => void
  ): RenderInputComponent<SearchResult> =>
  (inputProps) =>
    (
      <div className="autosuggest">
        <div className="tags">
          {values.map((result) => (
            <div
              className="tags has-addons"
              key={`${result.kind}-${result.label}`}
            >
              <button type="button" className="tag is-link">
                <strong>
                  {result.kind === "ingredient_group"
                    ? "Ingredient"
                    : "Product"}
                </strong>
                : <span>{result.label.toLowerCase()}</span>
              </button>
              <button
                type="button"
                className="tag is-delete"
                onClick={() => onRemoveSelected(result)}
              ></button>
            </div>
          ))}
        </div>
        <input {...(inputProps as any)} />
      </div>
    );

const renderSingleInputComponent: RenderInputComponent<SearchResult> = (
  inputProps
) => <input {...(inputProps as any)} />;

export default function SearchInput(props: Props) {
  const { isMulti, onRemoveSelected, onSelected, selected } = {
    onRemoveSelected: () => undefined,
    selected: [],
    ...props,
  };

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const debouncedCallback = useDebounceCallback(async ({ value }) => {
    const response = await fetch(`/api/search?q=${value}`);

    if (response.ok) {
      const result = await response.json();
      setSuggestions(result);
    } else {
      setSuggestions([]);
    }
  });
  const onSuggestionSelected = useCallback(
    (_, { suggestion }) => onSelected(suggestion),
    [onSelected]
  );
  const [renderInputComponent, inputProps] = useMemo(() => {
    const memoRenderInputComponent = isMulti
      ? renderMultiInputComponent(selected ?? [], onRemoveSelected)
      : renderSingleInputComponent;
    const memoInputProps = {
      placeholder: "Type an ingredient or product",
      value,
      onChange: (_, { newValue }) => setValue(newValue),
      className: "input is-large",
    } as InputProps<SearchResult>;

    return [memoRenderInputComponent, memoInputProps];
  }, [onRemoveSelected, renderSingleInputComponent, selected, value]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsFetchRequested={debouncedCallback}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
      inputProps={inputProps}
      alwaysRenderSuggestions={true}
    />
  );
}
