import { useAuth0 } from "@auth0/auth0-react";

import cn from "classnames";
import React from "react";
import { useToggle } from "react-use";

import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

export default function Header() {
  const { isAuthenticated } = useAuth0();
  const [active, toggle] = useToggle(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <a
        role="button"
        className={cn({ "navbar-burger": true, "is-active": active })}
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar"
        onClick={toggle}
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
      <div id="navbar" className={cn({ "navbar-menu": true, "is-active": active })}>
        <div className="navbar-start">
          <a className="navbar-item" href="/">
            Home
          </a>
          {isAuthenticated && (
            <a className="navbar-item" href="/profile">
              Profile
            </a>
          )}
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">{isAuthenticated ? <LogoutButton /> : <LoginButton />}</div>
          </div>
        </div>
      </div>
    </nav>
  );
}
